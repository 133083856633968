//
// Hero
// --------------------------------------------------

$home-hero-height: 100vh;



.hero {
  background-color: palette(brand, accent);
  color: palette(white);
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  .bg-img {
    // filter: grayscale(1);
  }

  .wrap {
    color: palette(white);
    position: relative;
    text-align: center;
    z-index: 3;
  }

  h1 {
    color: palette(white);
    line-height: 1;
  }

  h3 {
    color: palette(white);
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;

    @include to(lp) {
      font-size: rem-calc(18);
    }

    @include at(pt) {
      font-size: rem-calc(23);
    }
  }

  &__footer {
    position: absolute;
    bottom: rem-calc(30);
    left: 0;
    color: palette(white);
    font-size: rem-calc(15);
    text-align: center;
    text-transform: none;
    letter-spacing: normal;
    margin: rem-calc(0 60);
    font-weight: $font-weight--light;
    width: calc(100% - #{rem-calc(120)});
    z-index: 3;
  }

  &__down {
    cursor: pointer;
    position: absolute;
    bottom: rem-calc(30);
    z-index: 5;
    left: rem-calc(14);
    line-height: 0;
    transform: rotate(-90deg);

    svg {
      fill: white;
      transition: fill .3s ease-out;
      width: 22px;
    }

    &:hover {
      svg {
        fill: palette(brand, secondary);
      }
    }
  }

  &--home {
    height: 100vh;

    &::after {
      background: rgba(0, 0, 0, .3);
    }

    .hero__variants {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 2;

      .variant-pagination {
        position: absolute;
        top: auto;
        bottom: rem-calc(68);
        left: rem-calc(20);
        transform: none;
        z-index: 4;

        .swiper-pagination-bullet {
          background-color: transparent;
          border: palette(white) 1px solid;
          margin: rem-calc(10 0);
          position: relative;
          transition: border-color .3s ease-out;
          height: rem-calc(10);
          width: rem-calc(10);

          &::before {
            content: '';
            background-color: palette(white);
            border-radius: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            transition: transform .3s ease-out, background-color .3s ease-out;
            height: 100%;
            width: 100%;
          }

          &.swiper-pagination-bullet-active {
            &::before {
              transform: scale(1);
            }
          }

          &:hover {
            border-color: palette(brand, secondary);

            &::before {
              background-color: palette(brand, secondary);
            }
          }
        }

        @include to(lp) {
          display: none;
        }
      }
    }

    .hero__variant {
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      img {
        height: auto;
        max-height: 90%;
        width: auto;
      }

      @include at(pp) {
        justify-content: center;

        img {
          height: 90%;
          max-width: none;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: 10%;
        }
      }

      @include at(lp) {
        img {
          max-height: none;
          width: 60%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: 10%;
        }
      }

      &.swiper-slide-active {}
    }

    .hero__target {
      .target-option {
        transition: opacity 1s;

        &--additional {
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
        }

        &.is-active {
          opacity: 1;
        }
      }
    }

    // option
    @if $nav-in-hero==true {
      height: $home-hero-height;

      @include from(sd) {
        height: $home-hero-height;
        min-height: 700px;
      }
    }

    @else {
      height: calc(#{$home-hero-height} - #{rem-calc($header-height-mobile)});

      @include from(sd) {
        height: calc(#{$home-hero-height} - #{rem-calc($header-height-default)});
      }
    }


    .wrap {
      max-width: rem-calc(1250);
      text-align: left;
      margin: 0 0 0 auto !important;

      @include at(pp) {
        margin-top: 40%;
      }

      @include from(lt) {
        @include add-horizontal-spacing($spacing-unit--horz * 2);
      }

      h1 {
        font-size: 5.444444rem;
      }

      h3 {
        font-size: 1em;
        max-width: 23.78rem;
      }

      a {
        color: #fff;
      }
    }

    h1 {
      line-height: .9;
      position: relative;

      @include from(pt) {
        margin-left: rem-calc(-5);
      }

      @include from(lt) {
        margin-left: rem-calc(-8);
      }

      @include from(sd) {
        margin-left: rem-calc(-10);
      }
    }

    h3 {
      max-width: rem-calc(680);

      @include at(lp) {
        max-width: 380px;
      }
    }
  }

  &--internal {
    // min-width: 100vh;

    @include from(sd) {
      // min-height: 500px;
      // height: 100vh;
    }

    display: flex;
    flex-direction: column;

    &::before {
      @include overlay(.2);
      z-index: 1;
    }

    .wrap {
			min-height: 500px;
			@include from(sd){
				min-height: 300px;
			}
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &::after {
      @include overlay(.3, palette(brand, accent));
    }
  }
}
