.game {

  &__inner {
    @include add-horizontal-spacing;
    @include add-section-vertical-spacing;
    display: flex;
    flex-direction: column;

    @include from(sd) {
      flex-direction: row;
    }
  }

  &__content {

    border-bottom: 1px solid #979797;
    padding-bottom: 2rem;

    @include from(sd) {
      flex: 1 1 66.66%;
      padding-right: 4rem;
      padding-bottom: 0;
      border-right: 1px solid #979797;
      border-bottom: none;
    }
  }

  &__categories {
    padding-top: 2rem;

    @include from(sd) {
      flex: 1 1 33.33%;
      padding-left: 4rem;
      padding-top: 0;
    }
  }

  .link-holder {
    margin-top: 2rem;
  }
}

.game-video {
  @include add-horizontal-spacing;
  @include add-section-vertical-spacing('padding', $spacing-unit--vert, 'bottom');
  padding-top: 0;

  &__inner {
    height: 46.5vw;
    position: relative;
  }
}

.game-categories {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #979797;
  padding-bottom: 2rem;
}

.game-category {
  margin-right: 1.5rem;
}

.post-type-archive-game,
.archive {
  main.main.main {
		@include from(sd){
			padding:  3rem 0 2rem;
		}
    background-color: #f7f8f8;
  }
}
