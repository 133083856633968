.image-links {
  &.image-links {
    padding: 0;
  }

  .heading {
    @include add-horizontal-spacing;
    @include add-section-vertical-spacing('padding', $spacing-unit--vert / 2);
    background-color: palette(brand);
    color: palette(white);
    text-align: center;
    margin: 0;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }
}

.image-link {
  flex: 0 0 50%;
  height: 80vw;
  max-height: 80vh;
  overflow: hidden;
  position: relative;

  @include at(lp) {
    height: 50vw;
  }

  &__image {
    .bg-img {
      transition: all .4s ease;
      will-change: transform;
      width: 100.5%;
    }

    &::before {
      @include overlay(0.15, palette(black));
      transition: opacity .4s ease;
      z-index: 2;
    }
    
    &:hover {
      &::before {
        @include overlay(.3, palette(brand, accent));
      }
    }
  }

  &__text {
    color: palette(white);
    font-size: rem-calc(22);
    font-weight: normal;
    line-height: 1;
    position: absolute;
    top:  0;
    left: 0;
    text-transform: uppercase;
    transform: rotate(90deg);
    transform-origin: bottom left;
    z-index: 3;

    span {
      display: block;
      position: absolute;
      bottom: rem-calc(15);
      left:   rem-calc(20);
      width: 80vw;
      width: calc(80vw - #{rem-calc(40)});
      z-index: 3;
    }

    @include at(lp) {
      span {
        width: 50vw;
        width: calc(50vw - #{rem-calc(80)});
      }
    }

    @include from(pt) {
      font-size: rem-calc(25);

      span {
        bottom: rem-calc(30);
        left:   rem-calc(40);
        padding-right: rem-calc(30);
        width: calc(80vw - #{rem-calc(80)});
      }
    }
  }

  .chevron {
    display: none;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    bottom: rem-calc(40);
    left:   rem-calc(30);
    line-height: 0;
    transition: all .4s ease;
    width: rem-calc(25);
    z-index: 3;

    svg {
      stroke: palette(white);
      transform: rotate(270deg);
      width: rem-calc(19);
    }

    @include from(pt) {
      display: flex;
    }
  }

  &:hover {
    .image-link__image {
      .bg-img {
        transform: scale(1.03, 1.03);
      }

      &::before {
        opacity: .5;
      }
    }

    .chevron {
      transform: translate(25%, 0);
    }
  }

  @include at(lp) {
    flex: 0 0 25%;
  }

  @include from(lt) {
    flex: 0 0 25%;
    height: 50vw;

    &__text {
      span {
        width: 50vw;
        width: calc(50vw - #{rem-calc(80)});
      }
    }
  }
}