//
// Greedy Nav
// --------------------------------------------------

nav.greedy {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 4;
  width: 100%;

  button {
    align-self: stretch;
    transition: all .4s ease-out;
    outline: 0;
    border: 0;
    background: transparent;
    position: relative;
    padding: rem-calc(0 20 0 0);

    svg {
      height: rem-calc(20);
      width: rem-calc(20);
      fill: palette(brand, darker);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.hidden {
      transition: none;
      padding: 0;
      width: 0;
      overflow: hidden;
    }
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul {
    &.links {
      display: flex;
      justify-content: center;
      flex: 1;
      overflow: hidden;
      font-size: rem-calc(18);

      li {
        flex: none;

        a {
          display: block;
          padding: rem-calc(30 18);
          transition-property: all;
          transition-duration: .44s;
          transition-easing: ease;

          &.active,
          &:hover {
            color: $color--text;
          }
        }
      }
    }

    &.hidden-links {
      position: absolute;
      background: palette(brand);
      top: 100%;
      right: 0;
      padding: 0;
      transform: translateY(0);
      transition: all .35s ease;
      opacity: 1;
      z-index: 10;

      li {
        padding: 0;
      }

      a {
        color: palette(white);
        display: block;
        padding: rem-calc(10 15);
        transition: background-color .3s ease-in-out;

        &:hover {
          background-color: palette(brand, accent);

          &::after {
            display: none;
          }
        }
      }

      &.hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
      }
    }
  }
}

.filter {
  @include add-horizontal-spacing;
  background-color: palette(brand);
  text-align: center;

  &__option {
    color: palette(white);
    display: inline-block;
    font-size: rem-calc(18);
    text-transform: uppercase;
    position: relative;
    margin: 0;
    opacity: .8;

    >span {
      position: relative;
      z-index: 1;
    }

    &,
    &::after {
      transition: opacity .3s ease-in-out, transform .3s ease-in-out;
      transition-delay: .2s;
    }

    &::after {
      content: '';
      background-color: palette(white);
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: translateY(100%);
      height: 100%;
      width: 100%;
    }

    &.active,
    &:hover {
      opacity: 1;

      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:hover {

      &,
      &::after {
        transition-delay: 0s;
      }
    }

    &.active {

      &,
      &::after {
        // transition-delay: .2s;
      }

      .filter.is-focussed & {
        color: palette(white);
        transition-delay: 0s;

        &::after {
          transform: translateY(100%);
          transition-delay: 0s;
        }
      }
    }
  }
}

nav.filter-game {
  background: black;

  .filter-game__wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		p{
			margin-bottom: 0;
			margin-top: 1rem;
			font-size: .85rem;
		}
	}
  .filter-game__inner {
    display: flex;
    flex-direction: column;
		padding: 1rem 0;
		align-items: center;
		width: 100%;

    @include from (sd) {
			flex-direction: row;
			padding: 0;
    }


    >ul {
      display: flex;
      width: 100%;
      justify-content: space-between;


      >li {
        padding: 1rem 0;
        display: none;
        position: relative;
        cursor: pointer;
        align-items: center;
        width: 100%;

        @include from(sd) {
          width: auto;
          display: flex;
        }

        &:last-child {
          display: flex;
        }


        &:not(:last-child):after {
          content: "";
          width: 12px;
          height: 7px;
          display: block;
          position: absolute;
          bottom: 1rem;
          left: 50%;
          transform: translateX(-50%);
          background: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2213px%22%20height%3D%227px%22%20viewBox%3D%220%200%2013%207%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22%401440px---Normal-%2F-12-Columns-%2F-20px-Gutters-%2F-5%25-Margins%22%20transform%3D%22translate%28-549.000000%2C%20-462.000000%29%22%3E%3Crect%20id%3D%22Hero-Box%22%20fill%3D%22%23000000%22%20x%3D%220%22%20y%3D%22401%22%20width%3D%221440%22%20height%3D%2292%22%3E%3C%2Frect%3E%3Cg%20id%3D%22Group-4%22%20transform%3D%22translate%28168.000000%2C%20423.000000%29%22%20fill%3D%22%23FFFFFF%22%3E%3Cpolygon%20id%3D%22chevron-copy%22%20transform%3D%22translate%28387.248096%2C%2042.500000%29%20rotate%28180.000000%29%20translate%28-387.248096%2C%20-42.500000%29%20%22%20points%3D%22387.244081%2040.4497227%20381.976462%2045.9997156%20381.25%2045.3147946%20387.243509%2039%20393.246192%2045.3145102%20392.520271%2046%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
          background-size: cover;
        }

        >ul {
          position: absolute;
          top: calc(100% - .5rem);
          left: 50%;
          transform: translateX(-50%);
          background: white;
          padding: 1rem 0;
          display: none;
          min-width: 150px;
          text-align: left;

          &:before {
            position: absolute;
            content: '';
            top: -2px;
            left: 50%;
            width: 15px;
            height: 15px;
            background: white;
            transform: rotate(45deg) translateX(-50%);
            display: block;
          }
        }

        &:hover {

          &:after {
            content: initial;
          }

          >ul {
            display: block;
          }
        }

      }
    }

    li {
      white-space: nowrap;

      a {
        padding: .25rem 1rem;
        margin: 1px 0;
        display: block;
      }

      &.active a {
        background: palette('brand');
        color: white;
      }
    }



    .button {
      width: 100%;
      padding: 0.7rem 2rem;

      @include from (sd) {
        width: auto;
      }
    }

    label {
      margin-bottom: 1rem;
      text-align: left;
      width: 100%;

      @include from(sd) {
        display: none;
      }
    }
  }
}
