//
// Listing
// --------------------------------------------------

.listing {
  @include pie-clearfix;
  color: palette(white);
  display: block;
  margin-bottom: rem-calc(20);
  padding: rem-calc(0 10);
  transition: opacity .3s ease-out, transform .3s ease-out;
  width: 100%;

  &.lazyload {
    opacity: 0;
    transform: translateY(5%);
  }

  &.lazyloaded {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity .3s ease-out, transform .4s ease-out;
  }

  &__inner {
    background-color: palette(brand, dark);
    color: palette(white);
    font-weight: $font-weight--light;
    display: block;
    height: 100%;
    display: flex;
    flex-direction: column;
		transition: background-color .4s ease;
		
    &:hover {
			color: palette(white);
			text-decoration: none;
    }
  }

  &__image {
    overflow: hidden;
		position: relative;
		height: 50%;

    img {
      display: block;
      transition: transform .4s ease;
      // transition-delay: .1s;
			height: 100%;
			object-fit: cover;
      width:  100%;
    }

    &::before {
      @include overlay(0);
      transition: opacity .4s ease;
      z-index: 2;
    }
    
    &:hover {
      &::before {
        @include overlay(.3, palette(brand, accent));
      }
    }
  }

  header {
    margin-bottom: rem-calc(20);
  }

    time {
      font-size: rem-calc(15);
      font-weight: 700;
    }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: rem-calc(20 30);

    @include from(sd) {
      padding: rem-calc(50 50 60);
    }
  }

  &__title {
    color: palette(white);
    font-size: rem-calc(21);
    font-weight: $font-weight--bold;
    margin: 0;
  }

  &__summary {
    margin-bottom: rem-calc(20);

    &,
    p {
      font-size: rem-calc(16);
      line-height: 1.5;
    }
  }

  .button {
    margin-top: auto;
  }

  &__inner:hover &__inner {
    background-color: palette(brand, accent);
  }

  &__inner:hover &__image {
    img {
      transform: scale(1.03);
      // transition: transform .3s ease-in-out;
    }

    &::before {
      opacity: .5;
    }

    &::after {
      opacity: .15;
    }
  }

  @include from(lp) {
    width: percentage(1/2);
  }

  @include from(lt) {
    width: percentage(1/3);
  }
}
