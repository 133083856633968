//
// Variables
// --------------------------------------------------

// Leap Options
$nav-in-hero: true;


//== Palette
// --------------------------------------------------

// Base colours
$_color-base-grey:  #989899;
$_color-base-brand: #0073ae;


// Colour Palette
$palettes: (
  white: (
    base: #fff,
    dark: #f2f4f5,
  ),
  black: (
    base: #000,
    brand: darken($_color-base-brand, 54%)
  ),
  grey: (
    base:    $_color-base-grey,
    light:   #ced9de,
    lighter: lighten($_color-base-grey, 58.5%),
    dark:    #273237,
    darker:  darken($_color-base-grey, 20%),
  ),
  // brand: (
  //   base:      $_color-base-brand,
  //   accent:    #014568,
  //   light:     #41a0d0,
  //   dark:      #001824,
  //   complementary: complementary($_color-base-brand),
  //   secondary: #de1653
  // )
  brand: (
    base:      $_color-base-brand,
    text:      #292929,
    accent:    #2A476F,
    light:     #4475B9,
    dark:      #162538,
    complementary: complementary($_color-base-brand),
    secondary: #F7941D
  )
);


//== Colours
// --------------------------------------------------

// Typography
$color--text:    palette(brand, text);
$color--hover:   palette(brand, secondary);
$color--border:  palette(grey, light);


//== Typography
// --------------------------------------------------

// Weights
$font-weight--thin:       100;
$font-weight--light:      300;
$font-weight--regular:    400;
$font-weight--medium:     500;
$font-weight--semi-bold:  600;
$font-weight--bold:       700;
$font-weight--extra-bold: 800;
$font-weight--black:      900;

$font-weight--body:       $font-weight--light;
$font-weight--heading:    $font-weight--bold;

// Families
$_font-family--korolev-condensed: korolev-condensed, sans-serif;
$_font-family--roboto: roboto, sans-serif;

$font-family--body:    $_font-family--roboto;
$font-family--heading: $_font-family--roboto;
$font-family--special: $_font-family--korolev-condensed;

// Sizes
$font-size--base: 18px;
$line-height--base: 1.6;

$font-size--p:  17px;
$font-size--h1--home: 170px;
$font-size--h1: 72px;
$font-size--h2: 34px;
$font-size--h3: 25px;
$font-size--h4: 25px;
$font-size--h5: 21px;
$font-size--h6: 17px;

// Spacing
$letter-spacing--base: normal;

$letter-spacing--small:  0.6px;
$letter-spacing--medium: 1px;
$letter-spacing--large:  2px;

//== Hamburger
// --------------------------------------------------
$hamburger-padding-x:                        0px;
$hamburger-padding-y:                        0px;
$hamburger-layer-width:                     20px;
$hamburger-layer-height:                     2px;
$hamburger-layer-spacing:                    4px;
$hamburger-layer-border-radius:              4px;
$hamburger-layer-color:                      palette(brand, dark);
$hamburger-hover-opacity:                    0.7;
$hamburger-hover-transition-duration:        0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);

// Remove or comment out the hamburger types you don’t want
// or need, so they get excluded from the compiled CSS.
// $hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   slider,
//   slider-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
//   spin,
//   spin-r,
//   squeeze,
//   vortex,
//   vortex-r
// );
$hamburger-type: (
  spring
);

//== Grid
// --------------------------------------------------

// Set consistent vertical and horizontal spacing units.
$spacing-unit--vert:           120px !default;
$spacing-mod--vert--tab:         0.8 !default;
$spacing-mod--vert--mobile:      0.5 !default;

$spacing-unit--horz:           120px !default;
$spacing-mod--horz--tab:         0.5 !default;
$spacing-mod--horz--mobile:      0.3 !default;

$spacing-unit--component:       20px !default;
$spacing-mod--component--tab:    0.8 !default;
$spacing-mod--component--mobile: 0.5 !default;

$max-width--base:   600px;
$max-width--narrow: 500px;
$max-width--wide:   720px;
$max-width--xwide:  800px;

$vert-typographic-spacing: 45px;

$noquery: '.lt-ie9';

$grid-padding: 2rem;
$slicer-anti-overlap-corrections: -1px;

// Breakpoints
$slicer-breakpoints:  0        550px       768px       960px       1025px      1250px      1440px       1750px;

//                    └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘
// Slice numbers:          1           2           3           4           5           6           7           8
$slicer-breakpoint-names: 'pp'        'lp'        'pt'        'lt'       'sd'        'd'         'ld'        'vld';
